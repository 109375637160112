@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





* {
  font-family: 'Roboto', sans-serif;
}

/* -------------
  HOME PAGE
 -------------*/
 

.__home_section {
  padding-top: 0px;
}
.heading-text {
  font-size: 18px;
  font-weight: 600;
  padding-bottom:40px;
}
.__home_section h6 {
  font-size: 35px;
  font-weight: 900;
  padding-bottom: 50px;
  color: #014da8;
  padding-top: 148px;
}
.form input {
  width: 100%;
  margin: 20px 0 0 0;
  padding: 14px 23px;
  border: 2px solid #e8e8e8;
  border-radius: 9px;
  outline: none;
  font-weight: 500;
  background: #e8e8e8;
}
.form input::-webkit-input-placeholder {
  color: #676767;
  font-weight: 500;
}
.form input:-ms-input-placeholder {
  color: #676767;
  font-weight: 500;
}
.form input::placeholder {
  color: #676767;
  font-weight: 500;
}
.form button {
  margin-top: 30px;
  width: 200px;
  padding: 10px 25px;
  background: #014da8;
  border: 2px solid #014da8;
  border-radius: 36px;
  color: #fff;
  font-weight: 400;
  outline: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
}
.__home_section .__msg_right_block {
  /*background: #4db69f;*/
  background: url(../../static/media/one.8188e4df.jpg);
  background-size: cover;
  background-position: -25vw;
  min-height: 100vh;
  padding-top: 45%;
  color: #fff;
  position: relative;
}
.__home_section .__msg_right_block::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #014ea873;
}
.__home_section .__msg_right_block h1 {
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  color: #fff;
}
.__home_section .__msg_right_block p {
  font-size: 18px;
  position: relative;
}


/* -------------
  HOME PAGE
 -------------*/

.__header_section {
  height: 80px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 7px 13px #7b7b7b2b;
  padding: 24px 0;
}
.__header_section img {
  height: 34px;
  margin-left: 53px;
}
.__header_section button {
  width: 100px;
  padding: 10px 10px;
  background: #014da8;
  border: 2px solid #014da8;
  border-radius: 36px;
  color: #fff;
  font-weight: 400;
  outline: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
}
.add_new_button {
  padding: 10px 10px;
  background: #014da8;
  border: 2px solid #014da8;
  border-radius: 36px;
  color: #fff;
  font-weight: 400;
  outline: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
  align-self: flex-end;
}
.header_menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header_menu a{
  padding: 10px 10px;
  border-radius: 36px;
  color: #000;
  font-weight: 400;
  outline: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  text-align: center;
}
.setting-icon i {
  font-size: 25px;
  color: #000;
  margin-top: 5px;
}
.__date_tabs {
  padding-top: 30px;
}
.__date_tabs .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type), .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type), .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type), .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 20px;
  border-radius: 14px;
  background: #fff;
  text-align: center;
  padding: 13px 25px;
  font-size: 17px;
  color: #000;
}
.__date_tabs .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, 
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active,
.__date_tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    background: #014da8 !important; 
    color: #fff !important;
}

.__internal_tabs .ant-tabs-tab-active {

}

.add_btn {
  padding: 0px 9px;
  height: 40px;
  background: #014da8;
  border: 1px solid #014da8;
  color: #fff;
  outline: none;
  transition: all 0.6s;
}
.add_btn:hover {
  background: #000;
}
.add_btn i {
  font-size: 25px;
  font-weight: 500;
  padding: 3px 0;
}
.table_input {
  padding: 3px 11px;
  border: 1px solid #ddd;
  outline: none;
}
.table_qty_input {
  text-align: center;
  border: none;
  padding: 4px 0;
  outline: none;
  border: 1px solid #014da8;
}
.ope_btn {
  border: 1px solid #014da8;
  padding: 4px 8px;
  background: #014da8;
  color: #fff;
  outline: none;
}
.action_btns {
  border: 1px solid #014da8;
  padding: 4px 4px;
  background: #014da8;
  color: #fff;
  outline: none;
}
.action_btns_green {
  border: 1px solid #01a809;
  padding: 4px 4px;
  background: #01a809;
  color: #fff;
  outline: none;
}
.action_btns_delete {
  border: 1px solid #a80101;
  padding: 4px 4px;
  background: #a80101;
  color: #fff;
  outline: none;
}
.ope_btn.left {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}
.ope_btn.right {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.__productplan_section .ant-tabs-nav-list {
  margin: auto;
}
.__productplan_section  .__internal_tabs .ant-tabs-nav-list {
  margin: 0; 
}


.navbar {
  box-shadow: 0 0 10px #c4c4c4;
  padding: 17px 32px;
}
.navbar-nav a {
  text-transform: uppercase;
  color: #000 !important;
  font-size: 15px;
}
.logout-btn {
  padding: 4px 22px !important;
  background: #014da8 !important;
  color: #fff !important;
  height: 38px !important;
  border-radius: 20px !important;
}

.save-btn {
    padding: 0 20px;
    outline: none;
}

/*Mobile responsive setting*/

@media only screen and (max-width:600px) {

  #root {
    overflow: hidden;
 }

  .__productplan_section .ant-tabs-nav-list {
      width: 300px;
      margin-left: 0px;   
    overflow-x: scroll;
  }
  .__productplan_section .__date_tabs .ant-tabs {
    padding: 10px;
  }
  .ant-table {
    width: 314px;
    overflow-x: scroll;
  }
  .ant-table::-webkit-scrollbar {
   display: none;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #c0c0c0; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

} 



















